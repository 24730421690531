define(['siteObj'], (siteObj) => {
    var thgGeoFencing = () => {

        const component = {};

        const _init = (element) => {
            component.element = element;
            component.firePopup();
        }

        const _firePopup = () => {
          if (siteObj.customerLocation !== 'PK') {
            document.querySelector(".thgGeoFencingContainer").classList.remove("hidden");          
          }
        }

        component.init = _init;
        component.firePopup = _firePopup;

        return component;
    };

    return thgGeoFencing;
});
